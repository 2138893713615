<template>
  <div class="h-full flex flex-col">
    <div class=" flex justify-between text-black py-8">
      <span>共{{ list.length }}条记录</span>
      <span class=" cursor-pointer text-warning " @click="addAddress"
        >新增收货地址</span
      >
    </div>
    <div class="flex-1 overflow-auto">
      <div
        class="flex justify-between space-x-8 box-border p-16 border border-gray-300 rounded mb-8"
        v-for="address in list"
        :key="address.id"
      >
        <div
          class="w-40 h-40 rounded-full bg-warning bg-opacity-30 text-warning flex justify-center items-center"
        >
          {{ address.receiver ? address.receiver[0] : "收" }}
        </div>
        <div class="space-y-8 flex-1">
          <div class="flex justify-between items-center">
            <div class="text-lg flex justify-start items-center space-x-16">
              <span class="text-lg">{{ address.receiver }}</span>
              <span>{{ address.phone }}</span>
              <span
                v-if="address.isDefault"
                class="px-4 text-warning border border-warning rounded-sm text-sm"
                >默认</span
              >
            </div>
            <div class="space-x-16">
              <i
                class="iconfont icon-fill_complete text-warning cursor-pointer"
                v-if="address.isDefault"
              ></i>
              <i
                class="iconfont icon-radio_nor text-gray-900 cursor-pointer"
                v-else
                @click="setDefaultAddress(address.id)"
              ></i>
              <span
                class="text-gray-900"
                :class="{
                  'text-warning': address.isDefault,
                }"
                >默认地址</span
              >
              <i
                class="iconfont icon-line_edit text-warning cursor-pointer"
                @click="editAddress(address)"
              ></i>
              <i
                class="iconfont icon-line_delete cursor-pointer"
                @click="deleteAddress(address.id)"
              ></i>
            </div>
          </div>
          <div>
            {{ address.provinceName }}/{{ address.cityName }}/{{
              address.areaName
            }}
          </div>
          <div class="text-black-100">
            {{ address.address }}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增/修改地址"
      :visible.sync="dialogVisible"
      :width="'35%'"
    >
      <EditAddressInfo
        v-if="dialogVisible"
        @cancle="dialogVisible = false"
        @afterOperate="init"
      />
    </el-dialog>
    <el-dialog
      title="新增/修改地址"
      :visible.sync="dialogVisible2"
      :width="'35%'"
    >
      <EditAddressInfo
        v-if="dialogVisible2"
        :addressInfo="addressInfo"
        @cancle="dialogVisible2 = false"
        @afterOperate="init"
      />
    </el-dialog>
  </div>
</template>
<script>
import EditAddressInfo from "./components/EditAddressInfo.vue";
import {
  addressesListApi,
  deleteAddressApi,
  setDefaultAddressApi,
} from "@/api/address";
export default {
  components: {
    EditAddressInfo,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      list: [],
      addressInfo: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      addressesListApi().then((res) => {
        this.list = res;
      });
    },
    addAddress() {
      this.dialogVisible = true;
    },
    editAddress(info) {
      this.addressInfo = info;
      this.dialogVisible2 = true;
    },
    deleteAddress(id) {
      this.$confirm("确定删除这条地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteAddressApi(id).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.init();
          });
        })
        .catch(() => {});
    },
    setDefaultAddress(id) {
      this.$confirm("确定将此地址设置为默认地址?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setDefaultAddressApi(id).then(() => {
            this.$message({
              type: "success",
              message: "设置成功!",
            });
            this.init();
          });
        })
        .catch(() => {});
    },
  },
};
</script>
